<template>
  <window-portal :open="dataProps.show">
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center>
        <div>
          <p style="font-size: 30px">
            Vista previa de impresión Estado de Cuenta
          </p>
        </div>
      </center>
      <br />
      <center>
        <button class="btnclean" style="font-size: 18px" @click="Print()">
          Imprimir
        </button>
        &nbsp;&nbsp;
        <button class="btnclean" style="font-size: 18px" @click="Download()">
          Descargar
        </button>
      </center>
      <center>
        <div v-if="loading"><p>Cargando...</p></div>
      </center>
    </div>
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center v-if="mailLabel">
        {{ mailLabel }}
      </center>
    </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style=" font-size: 12px;">
                                        <div style="display: flex; justify-content: space-between;">
                                            <h3 style="font-size: 16px">{{dataProps.header.busniessName}}</h3>
                                            <h3 style="font-size: 12px">Fecha: {{ dataProps.header.currentDate  }}</h3>
                                        </div>
                                        <h4>{{dataProps.header.title }}</h4>
                                        <p>{{dataProps.header.subTitle }}</p>
                                        <p>{{dataProps.header.customer.LegalName  }}</p>
                                        <p>Periodo de movimiento: {{ dataProps.header.date }} hasta {{dataProps.header.currentDate }}</p>
                                                    
                                                  
                                    </div>
                                    
                                    <br/>
                                    <!-- Heres is teh body -->
                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                            <tr style="background: gray; ">
                                                <td colspan="13" style="text-align: center; color: white;background: gray;">CUERPO DEL DOCUMENTO</td>
                                                
                                            </tr>
                                            
                                            <tr>
                                                
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">No.<br/>Item</td>                                           
                                                <td style="border: 1px solid; padding-left: 2px; width: 250px;">Factura</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 150px;">Fecha</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 450px;">Codigo Generacion</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 600px;">Concepto</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 100px; text-align: right;">Cargo</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 100px; text-align: right;">Abono</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 100px; text-align: right;">Saldo</td>
                                                
                                                 
                                            </tr>
                                            <!--tr v-for="(item, index) in dataProps.data.body" :key="index"-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                               
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.index }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ID_Invoice }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.PaymentDate ? item.PaymentDate : item.InvoiceDate }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.CodigoGeneracion }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.Note }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ item.PaymentWithIvaWithoutNc }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ item.Abonado }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas( item.PendingToPay ) }}</td>
                                                
                                            </tr>

                                            

                                        </table>
                                        <br/>
                                        <div v-if="indexPage == numPages - 1" style="font-size: 11px;">
                                            <div style="display: flex;">
                                            <div style="width: 70%;">

                                            </div>
                                            <table style="width: 40% !important; font-size: 11px; border-collapse: collapse; ">
                                                <tr>
                                                    <td style="width: 100px; text-align: center;">Saldo al {{dataProps.header.currentDate }}</td>
                                                    <td style="width: 100px; text-align: right ; ">{{ numberWithCommas( dataProps.footer.TotalPaymentShow ) }}</td> 
                                                </tr>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

              <div
                style="position: absolute; bottom: 0; width: 100%; left: 80px"
              >
                <p style="font-size: 10px">
                  Pagina {{ indexPage + 1 }} de {{ numPages }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import { numberToText } from "@/helpers/conveterNumberText.js";

export default {
    name: "PrintWithholding",
    props: ['dataProps'],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            Currency: JSON.parse( localStorage.getItem( "branch" ) ).Currency
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
        this.getHeaderFooter()
    },
    computed: {
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        },
        'dataProps'() {
            console.log('dataProps');
            this.calculatePagesOfDoc()
        }
    },
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return `${this.Currency}${numberWithCommas(x)}`;
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

    calculatePagesOfDoc() {
      let pages = 0;
      let rows = 0;
      this.valuesRows[pages] = [];

      try {
        this.typePrint = 0;
        //Cuando es solo una página
        if (this.dataProps.data.body.length <= 30) {
          for (let i = 0; i < this.dataProps.data.body.length; i++) {
            rows++;
            this.valuesRows[pages].push(this.dataProps.data.body[i]);
          }
        } else {
          for (let i = 0; i < this.dataProps.data.body.length; i++) {
            if (rows < 30 && i !== this.dataProps.data.body.length - 1) {
              rows++;
              this.valuesRows[pages].push(this.dataProps.data.body[i]);
            } else if (rows <= 20) {
              rows++;
              this.valuesRows[pages].push(this.dataProps.data.body[i]);
            } else {
              this.valuesRows[pages].push(this.dataProps.data.body[i]);
              pages++;
              rows = 0;
              this.valuesRows[pages] = [];
            }
          }
        }
      } catch (err) {
        alert(err);
      }
      pages = pages + 1;
      this.numPages = pages;
    },

    async Print() {
      this.loading = true;
      var doc = new jsPDF("p", "pt", "letter");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.612,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      doc.autoPrint({ variant: "non-conform" });
      const blob = doc.output("bloburl");
      window.open(blob);

      this.loading = false;
    },

    async Download() {
      this.loading = true;
      var doc = new jsPDF("p", "pt", "letter");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.612,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      doc.save(
        "Estado de Cliente - " +
          this.dataProps.header.customer.LegalName +
          new Date().toISOString() +
          ".pdf"
      );

      this.loading = false;
    },
    async sendMail() {
      try {
        this.mailLabel = null;
        var doc = new jsPDF("p", "pt", "letter");
        await doc.html(this.$refs.dataprint, {
          callback: function (doc) {
            doc.output();
          },
          x: 0,
          y: 0,
          margin: [0, 0, 0, 0],
          html2canvas: {
            scale: 0.612,
            useCORS: true,
            logging: false,
            allowTaint: true,
          },
        });

        const body = {
          dtefilebuffer: new Buffer.from(doc.output("arraybuffer")),
          dtejson: this.dataProps.data.dteJson,
          dtefilebufferfilename:
            this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', "") +
            ".pdf",
        };

        this.$API.invoices.sendInvoiceMail(body);

        this.mailLabel = "Se envió el correo correctamente";
        this.$emit("mailSent");
      } catch (error) {
        this.mailLabel = "Ocurrió un error al enviar el correo";
        console.log(error);
      }
    },
    async downLoadJSON() {
      try {
        const aElement = document.createElement("a");
        aElement.setAttribute(
          "download",
          this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', "") +
            ".pdf"
        );
        const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], {
          type: "application/json",
        });
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.download =
          this.dataProps.data.header.ID_Invoice +
          "_" +
          this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
          "_" +
          this.dataProps.data.header.InvoiceName.replaceAll(
            /[:/<>*|/]/g,
            ""
          ).replaceAll('\\"', "") +
          ".json";
        aElement.click();
        URL.revokeObjectURL(href);
      } catch (error) {
        this.mailLabel = "Ocurrió un error al enviar el correo";
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.u-row .u-col-100 {
  width: 100% !important;
}
.btnclean {
  border-radius: 15px;
  border: 1px solid white;
  background-color: #f29d35 !important;
  color: white !important;
}
.btnclose {
  border-radius: 15px;
  border: 1px solid white;
  background-color: rgb(255, 38, 38) !important;
  color: white !important;
}

* {
  font-family: Arial, Helvetica, sans-serif !important;
}
</style>
