<template>
    <v-card style="overflow-y: auto;">
        <block v-if="loading" />
        <v-card-title>
            Historial de abonos y facturas a crédito            
        </v-card-title>
        <v-card-subtitle>
            <b>ID:</b> {{ Customer.ID_Customer }} <b>Cliente:</b> {{ Customer.LegalName }}
        </v-card-subtitle>
        <v-tabs align-tabs="center" v-model="tab" >
            <v-tab key="tab1"  href="#tab-1" outlined>
                <b>Historial de Abonos</b>
            </v-tab>
            <v-tab key="tab2"  href="#tab-2" outlined>
                <b>Facturas con crédito pendientes </b>
            </v-tab>
            <v-tab key="tab3"  href="#tab-3" outlined>
                <b>Facturas con crédito canceladas</b>
            </v-tab>
        </v-tabs>
            <v-tabs-items  v-model="tab" touchless>
            <v-tab-item key="tab1" value="tab-1">
                <v-card-text>
                    <GeneralFilter  class="background border-box" :filterEndpoint="$API.customers.getFilterpaymentsByInvoice" :search="search" 
                    @emptyFilter="getAllpaymentsByInvoice" @filtered="filterHandler">
                        <DxDataGrid 
                        :dataSource="DataTable"
                        :showBorders="true"
                        ref="dataGrid"
                        :scrolling="{ useNative: true }"
                        @exporting="onExporting"
                        :paging="{ enabled: false }"  
                        style="max-height: 600px;"
                        >
                            <DxExport :enabled="true" />
                            <DxColumn dataField="ID_Invoice" caption="ID de Factura" />
                            <DxColumn  dataField="CodigoGeneracion" caption="Código de Generación" />
                            <DxColumn  dataField="PaymentShow" caption="Pago Realizado" />
                            <DxColumn  dataField="PaymentType" caption="Tipo de pago" />

                            <DxColumn :group-index="0" data-field="ID_AccountsReceivable" caption="ID de Abono" :group-cell-template="getCustomCell" />

                            <DxPager :visible="true" :show-page-size-selector="false" :show-info="false" :show-navigation-buttons="true" />
                            <!--DxPaging :page-size="10" /-->
                        </DxDataGrid>
                    </GeneralFilter>
                </v-card-text>
            </v-tab-item>

            <v-tab-item key="tab2" value="tab-2">
                <v-card-text>
                    <v-row class="mx-1">
                        <v-col cols="12" md="12" class="d-flex justify-end ">
                            <v-btn class="btn-add" @click="printAll(false)">
                                <v-icon>mdi-printer</v-icon>  
                            </v-btn>
                        </v-col>
                    </v-row>
                    <GeneralFilter  class="background border-box" :filterEndpoint="$API.customers.getFilterInvoiceWithCredit" :search="search" @emptyFilter="getInvoiceWithCredit" @filtered="filterHandler2">
                        <DxDataGrid :dataSource="InvoiceWithCredit" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true" :paging="{ enabled: false }" style="max-height: 600px">
                            <DxColumn dataField="ID_Customer" caption="ID Cliente o Negocio" />
                            <DxColumn dataField="LegalName" caption="Nombre Legal del Cliente" />
                            <DxColumn dataField="ID_Invoice" caption="ID del documento" />
                            <DxColumn dataField="InvoiceDate" caption="Fecha de Emisión" />
                            <DxColumn dataField="InvoiceTime" caption="Hora de Emisión" />
                            <DxColumn dataField="NumeroControl" caption="Número de Control" />
                            <DxColumn dataField="CodigoGeneracion" caption="Código de Generación" />
                            <DxColumn dataField="PaymentDate" caption="Fecha de vencimiento" />
                            <DxColumn dataField="InvoiceCreditDay" caption="Días de Crédito" />
                            <DxColumn dataField="PaymentWithIvaWithoutNc" caption="Monto" />
                            <DxColumn dataField="Abonado" caption="Abonado" />
                            <DxColumn dataField="PendingToPay" caption="Saldo" />

                            <DxPager :visible="true" :show-page-size-selector="false" :show-info="false" :show-navigation-buttons="true" />
                            <!--DxPaging :page-size="10" /-->
                        </DxDataGrid>
                    </GeneralFilter>
                </v-card-text>
            </v-tab-item>
            <v-tab-item key="tab3" value="tab-3">
                <v-card-text>
                    <v-row class="mx-1">
                        <v-col cols="12" md="12" class="d-flex justify-end ">
                            <v-btn class="btn-add" @click="printAll(true)">
                                <v-icon>mdi-printer</v-icon>  
                            </v-btn>
                        </v-col>
                    </v-row>
                    <GeneralFilter  class="background border-box" :filterEndpoint="$API.customers.getFilterInvoiceWithCredit" :search="search3" @emptyFilter="getInvoiceWithCredit(true)" @filtered="filterHandler3">
                        <DxDataGrid :dataSource="InvoiceWithCreditCanceled" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true" :paging="{ enabled: false }" style="max-height: 600px">
                            <DxColumn dataField="ID_Customer" caption="ID Cliente o Negocio" />
                            <DxColumn dataField="LegalName" caption="Nombre Legal del Cliente" />
                            <DxColumn dataField="ID_Invoice" caption="ID del documento" />
                            <DxColumn dataField="InvoiceDate" caption="Fecha de Emisión" />
                            <DxColumn dataField="InvoiceTime" caption="Hora de Emisión" />
                            <DxColumn dataField="NumeroControl" caption="Número de Control" />
                            <DxColumn dataField="CodigoGeneracion" caption="Código de Generación" />
                            <DxColumn dataField="PaymentDate" caption="Fecha de vencimiento" />
                            <DxColumn dataField="InvoiceCreditDay" caption="Días de Crédito" />
                            <DxColumn dataField="PaymentWithIvaWithoutNc" caption="Monto" />
                            <DxColumn dataField="Abonado" caption="Abonado" />
                            <DxColumn dataField="PendingToPay" caption="Saldo" />
                            <!-- <template #due-date="{ data }">
                                <div>
                                    {{  calculateDueDate(data.data.InvoiceDate, data.data.InvoiceCreditDay) }}
                                </div>
                            </template>
                            <DxColumn cell-template="cell-canceled" caption="Abonado" width="100" />
                            <template #cell-canceled="{ data }">
                                {{ Currency + data.data.Canceled.toFixed(2) }}
                            </template>
                            <DxColumn cell-template="cell-debt" caption="Monto Inicial" width="100" />
                            <template #cell-debt="{ data }">
                                {{ Currency + data.data.InvoiceTotalPagar.toFixed(2) }}
                            </template>
                            <DxColumn cell-template="cell-pending" caption="Pendiente" width="100" />
                            <template #cell-pending="{ data }">
                                {{ Currency + data.data.PendingToPay.toFixed(2) }}
                            </template> -->

                            <DxPager :visible="true" :show-page-size-selector="false" :show-info="false" :show-navigation-buttons="true" />
                            <!--DxPaging :page-size="10" /-->
                        </DxDataGrid>
                    </GeneralFilter>
                </v-card-text>
            </v-tab-item>
        </v-tabs-items>
      
        <div v-for="win in windowsList" :key="win.key">
             <component :is="win.component"  v-bind="win.props" />
        </div>

        <alerts
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        >
        </alerts>
    </v-card>
</template>

<script>
    import GeneralFilter from '@/components/GeneralFilter.vue';
    import PrintWithholdingID from '@/components/ReportsAndPrintings/PrintWithholdingID.vue';
    import PrintWithholding from '@/components/ReportsAndPrintings/PrintWithholding.vue';
    import {DxPager,DxColumn, DxDataGrid, DxExport} from 'devextreme-vue/data-grid';
    import Alerts from "@/components/Alerts";
    import { printToExcel } from "@/helpers/printToexcel";
import moment from 'moment';
    export default {
        name: "AccountsReceivableHistory",
        components: {
            DxPager,
            DxColumn,
            DxDataGrid,
            GeneralFilter,
            PrintWithholdingID,
            PrintWithholding, 
            DxExport,
            Alerts
        },
        props: ['win', 'Customer', 'typeData'],
        data() {
            return {
                DataTable: [],
                InvoiceWithCredit: [],
                InvoiceWithCreditCanceled: [],
                panel: 0,
                tab: null,
                key: 0,
                search3: {
                    ID_Customer: this.Customer.ID_Customer,
                    typeData: this.typeData,
                    isCancel: true
                },
                search: {
                    ID_Customer: this.Customer.ID_Customer,
                    typeData: this.typeData
                },
                idsAcountsReceivable: [],
                ID_AccountsReceivable: null,
                print: {
                    show : false,
                    header: {},
                    data: []
                },
                windowsList: [],
                Currency: JSON.parse(localStorage.getItem('branch')).Currency,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                ReceiptToDelete: null,
                loading: false
            }
        },
        computed:{  
            
        },
        methods: {
            closeAlert() {
                this.alert.show = false;
            },
            acceptAlert() {
                this.alert.show = false;

                if (this.alert.options == "eliminarabono") {
                    this.deleteReceipt();
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            printOne(ID){
                this.print.header = {
                    title: 'Estado de cuenta',
                    subTitle: 'ID de Abono: ' + ID,
                    busniessName: JSON.parse(localStorage.getItem('user')).business,
                    date: this.DataTable.find((item)=> item.ID_Receipt == ID || item.ID_AccountsReceivable == ID).PaymentDate,
                    customer: this.Customer,
                    currentDate: moment().format('DD/MM/YYYY'),
                },
                this.print.data.body = this.DataTable.filter((item)=> item.ID_Receipt == ID || item.ID_AccountsReceivable == ID);
                 const TotalPayment = this.print.data.body.reduce((a, b)=> a + b.Payment, 0);
                this.print.data.body.forEach((item, index)=>{
                    item.index = index + 1;
                })
                this.print.show = true;
                this.print.footer = {
                    TotalPayment: TotalPayment.toFixed(2),
                    TotalPaymentShow: JSON.parse(localStorage.getItem('branch')).Currency + " " + TotalPayment.toFixed(2)
                }
                this.windowsList.push({
                component: PrintWithholdingID,
                props: {
                    dataProps: this.print,                
                },
                key: new Date().getTime(),
            });
            },
            printAll(isCancel = false){

                this.print = {
                    show : false,
                    header: {},
                    data: []
                }
                this.print.header = {
                    title: 'Estado de Cuenta',
                    subTitle: '',
                    busniessName: JSON.parse(localStorage.getItem('user')).business,
                    date:  this.findOldDate(isCancel ? this.InvoiceWithCreditCanceled : this.InvoiceWithCredit),
                    customer: this.Customer, 
                    currentDate: moment().format('DD/MM/YYYY')
                }
                const Tansactions = [];
                const ValuesToMap = isCancel ? this.InvoiceWithCreditCanceled : this.InvoiceWithCredit;
                ValuesToMap.forEach((item)=>{
                    item.Note = 'Cargo por nuevo credito a factura ' +  this.calculateDueDate(item.InvoiceDate, item.InvoiceCreditDay)
                    Tansactions.push({ ...item, Cred: item.InvoiceTotalPagar  });
                });
                this.print.data.body = Tansactions.map((item, index)=>{
                    item.index = index + 1;
                    return item
                });
                
                this.print.data.body = Tansactions.filter(item => !!item.PendingToPay);
                this.print.show = true;
                this.print.footer = {
                   //last balance of transactions 
                    TotalPaymentShow: Tansactions.reduce( (accum, current) => accum + current.PendingToPay, 0 ),
                }
                this.windowsList.push({
                    component: PrintWithholding,
                    props: {
                        dataProps: this.print,                
                    },
                    key: new Date().getTime(),
                });

            },
            handlerAcountsReceivable(data = []){
                this.idsAcountsReceivable = [];
                data.forEach((item)=>{
                    if(item.ID_Receipt != null){
                        this.idsAcountsReceivable.find((id) => 
                        id == item.ID_Receipt) 
                            ? null 
                            : this.idsAcountsReceivable.push(item.ID_Receipt)
                    }else{
                        this.idsAcountsReceivable.find((id) => 
                        id == item.ID_AccountsReceivable) 
                            ? null 
                            : this.idsAcountsReceivable.push(item.ID_AccountsReceivable)
                    }
                })
            },
            filterHandler3(data){
                this.InvoiceWithCreditCanceled = data
            },
            findOldDate(data){
                
            // Extraer todas las fechas en formato de cadena
            const datesAsString = data.map((item) => item.InvoiceDate);

            // Convertir las cadenas en objetos Date
            const datesAsDate = datesAsString.map((dateStr) => {
                const [day, month, year] = dateStr.split('/').map(Number);
                return new Date(year, month - 1, day); // Restar 1 al mes porque en JavaScript los meses comienzan desde 0
            });

            // Encontrar la fecha más antigua
            const oldDate = datesAsDate.reduce((a, b) => (a < b ? a : b));

            return oldDate.toLocaleDateString("es-ES", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            });
            },
            resolveDef(data){
                return data.data.Btn ? true : false
                        },
            filterHandler(data){   
                this.ID_AccountsReceivable = null
                this.handlerAcountsReceivable(data);
                const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
                this.DataTable = data.map((item)=>{
                    item.PaymentShow = Currency + " " + item.Payment.toFixed(2)
                    return item
                });
            },
            filterHandler2(data){
                this.InvoiceWithCredit = data
            },
            getAllpaymentsByInvoice(){
                this.loading = true;
                this.$API.customers.getAllpaymentsByInvoice(this.search)
                .then((res)=>{
                    this.handlerAcountsReceivable(res)
                    this.loading = false;
                    const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
                    this.DataTable = res.map((item)=>{
                        item.PaymentShow = Currency + " " + item.Payment.toFixed(2)
                        return item
                    });
                })
                .catch((err)=>{
                    console.log(err);
                    this.loading = false;
                })
            },

            getCustomCell(cellElement, cellInfo) {
                const data = cellInfo.data.items[0]
                const groupContent = "ID Abono: " + (data.ID_Receipt != null ? data.ID_Receipt : data.ID_AccountsReceivable) +  
                             " - Fecha de Abono: " + data.PaymentDate + 
                             " - Total de Abono: $" + parseFloat(data.TotalPayment).toFixed(2) + " ";
                
                const span = document.createElement('span');
                span.textContent = groupContent;

                const button = document.createElement('button');
                button.textContent = 'Eliminar';
                button.setAttribute('data-group-id', data.ID_Receipt || data.ID_AccountsReceivable);

                button.style.backgroundColor = '#e74c3c'; /* Rojo brillante */
                button.style.color = 'white'; /* Texto blanco */
                button.style.border = 'none'; /* Sin borde */
                button.style.borderRadius = '4px'; /* Bordes redondeados */
                button.style.padding = '8px 16px'; /* Espaciado interno */
                button.style.fontSize = '14px'; /* Tamaño de fuente */
                button.style.cursor = 'pointer'; /* Cambia el cursor al pasar sobre el botón */
                button.style.transition = 'background-color 0.3s ease';
                button.style.float = 'right'

                const button2 = document.createElement('button');
                button2.textContent = 'Imprimir';

                button2.style.backgroundColor = '#1976d2'; /* Rojo brillante */
                button2.style.color = 'white'; /* Texto blanco */
                button2.style.border = 'none'; /* Sin borde */
                button2.style.borderRadius = '4px'; /* Bordes redondeados */
                button2.style.padding = '8px 16px'; /* Espaciado interno */
                button2.style.fontSize = '14px'; /* Tamaño de fuente */
                button2.style.cursor = 'pointer'; /* Cambia el cursor al pasar sobre el botón */
                button2.style.transition = 'background-color 0.3s ease';
                button2.style.float = 'right'
                button2.style.marginRight = '5px'

                cellElement.appendChild(span);
                cellElement.appendChild(button);
                cellElement.appendChild(button2);

                button.addEventListener('click', () => {
                    this.deleteGroup(data.ID_Receipt || data.ID_AccountsReceivable);
                });

                button2.addEventListener('click', () => {
                    this.printOne(data.ID_Receipt || data.ID_AccountsReceivable);
                });
            }, 
            deleteGroup(id) {
                this.ReceiptToDelete = id
                this.showAlert("question", "Eliminar", "¿Eliminar el abono seleccionado?", "eliminarabono")
            },
            deleteReceipt() {
                this.$API.customers.deleteReceipt({ ID_Receipt: this.ReceiptToDelete })
                    .then(response => {
                        if (response.status == "OK") {
                            this.getAllpaymentsByInvoice()
                            this.showAlert("success", "Éxito", "Se ha eliminado el abono seleccionado.")
                        }
                        else {
                            this.showAlert("warning", "Advertencia", "Ocurrió un error al intentar eliminar el abono seleccionado.")
                        }
                    })
                    .catch(() => {
                        this.showAlert("warning", "Advertencia", "Ocurrió un error al intentar eliminar el abono seleccionado.")
                    })
            },
            getInvoiceWithCredit(isCancel = false){
                let search = {
                    ID_Customer: this.Customer.ID_Customer,
                    typeData: this.typeData,
                    isCancel
                }
                this.$API.customers.getAcountsReceivableDetailedByCustomer(search)
                .then((res) =>{
                    if(isCancel){
                        this.InvoiceWithCreditCanceled = res
                    }else{
                        this.InvoiceWithCredit = res
                    }
                })
                .catch((err) =>{
                    console.log(err)
                })
            },
            calculateDueDate(originDate, invervalDay){
                var parts = originDate.split('/');
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1] - 1, 10);
                var year = parseInt(parts[2], 10);

                const onlyDigits = invervalDay.replace(/\D/g, "");
                var days = parseInt(onlyDigits, 10);

                var date = new Date(year, month, day);

                date.setDate(date.getDate() + days);

                var newDay = date.getDate().toString().padStart(2, '0');
                var newMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                var newYear = date.getFullYear();
                var newDate = newDay + '/' + newMonth + '/' + newYear;

                return newDate
            },
            onExporting(e) {
                printToExcel(e, 'Cuentas por Cobrar');
            },
        },
        mounted(){
            this.getAllpaymentsByInvoice();
            this.getInvoiceWithCredit();
            this.getInvoiceWithCredit(true);
        }

    }
</script>

<style scoped>

</style>